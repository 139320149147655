import { useRollbar } from '@rollbar/react'
import React from 'react'
import CommonPopup from 'common/components/entities/Popup/RemotePopup'
import { GlobalSettingsInterface } from 'common/types/Page'
import { OldEntityWithChildIds } from 'common/types/entities/OldEntityInterface'
import { closeRemotePopup } from 'publisher/actionsManagement'
import BaseNodeChildren from 'publisher/components/ChildrenEntities'

export interface RemotePopupProps {
  entity: OldEntityWithChildIds
  overflow?: string
  maxHeight?: string
  globalSettings: GlobalSettingsInterface
}

const RemotePopup = ({
  entity,
  overflow = 'visible',
  maxHeight = 'calc(100vh - 40px)',
  globalSettings,
}: RemotePopupProps) => {
  const rollbar = useRollbar()

  const onCloseHandler = (popupId: string, closedDays: number) => {
    try {
      closeRemotePopup(popupId, closedDays)
    } catch (e) {
      rollbar.error('Popup close failed', e as Error)
    }
  }

  const { showClose = false, closedDays } = entity.options
  const styles = globalSettings.fontFamily
    ? { ...entity.styles, fontFamily: globalSettings.fontFamily }
    : entity.styles

  return (
    <CommonPopup
      onCloseHandler={() => onCloseHandler(entity.id, closedDays)}
      styles={styles}
      showClose={showClose}
      overflow={overflow}
      maxHeight={maxHeight}
      mobileOptions={null}
      mobileStyles={null}
    >
      <BaseNodeChildren childIds={entity.childIds} />
    </CommonPopup>
  )
}

export default RemotePopup
